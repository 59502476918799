<template lang="pug">
#CustomFields
  v-row.setting-title.align-center.py-3.px-4(no-gutters)
    v-col(cols="12", md="7").mr-auto
      h3.d-flex.align-center.mb-0
        .cube-icon.small.bg-primary.mr-3
          v-icon(size="19", color="white") icon-folder
        span {{$t('SETTING.CUSTOMFIELDS')}}
  .px-4.py-3 
    v-row(no-gutters)
        v-col(cols="8").d-flex.justify-start
            label.mr-4 {{$t('CUSTOMFIELDS.SELECT_PAGE')}}
                v-select(v-model='tab' :items='items' item-value='key' item-text='name' height='34' flat dense solo background-color='#F0F0F0')
            label(v-if='tab=="Opportunity"|tab=="Case"') {{$t('CUSTOMFIELDS.SELECT_PROCESS')}}
                v-select(v-if='tab=="Opportunity"' v-model='deal_stage_type' :loading="isLoading" :items='stageDealsList' item-value='id' item-text='stage_type_name' prepend-inner-icon='icon-step' height='34' flat dense solo background-color='#F0F0F0')
                v-select(v-if='tab=="Case"' v-model='case_stage_type' :loading="isLoading" :items='stageCasesList' item-value='id' item-text='stage_type_name' prepend-inner-icon='icon-step' height='34' flat dense solo background-color='#F0F0F0')
        v-col(cols="4").d-flex.justify-end
          v-btn.mt-6(@click="showAddCustomField",height="40",color="green",depressed,dark)
            v-icon.mr-1(size="14") icon-add
            span {{$t('CUSTOMFIELDS.ADD_FIELD')}}  
    section(v-if='tab=="Opportunity"')
      //- h3 Deal 
      v-data-table.dashboard-table(
        item-key="editId",
        :item-class="rowClass"
        v-sortable-data-table,
        @sorted="saveOrder($event, 'Opportunity')",
        :headers="dataFieldsHeader",
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}",
        :items="DealsSortDefinition",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        :search="searchMangeUser",
        height="600",
        fixed-header,
        hide-default-footer="hide-default-footer",
        dense
      )
        template( v-slot:item.label="{ item }" )
            v-text-field.v-text-field__slot(id="CFtext" v-model="item.label" hide-details="true"  dense single-line :autofocus="true"  v-if="item.editId === editedItem.editId")
            .ellipsis(v-else :title='item.label')
                span {{item.label}}
        template(v-slot:item.type="{ item }")         
            .ellipsis(v-for="i in fieldTypes" v-if="item.type == i.value")
                span {{i.text}}
        template(v-slot:item.mandatory="{ item }")
          v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.mandatory" hide-details="auto" v-if="item.editId === editedItem.editId && item.type != 'bol'") 
          v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.mandatory" hide-details="auto" v-else readonly) 
        template(v-slot:item.active="{ item }")
          v-btn(icon, @click="editOpt(item)" v-if="item.editId === editedItem.editId && (item.type ==='opt' || item.type ==='mlt' || item.type ==='tst')")
            v-icon(size="18" color="blue") fa-solid fa-bars
          v-btn(icon, @click="close('Opportunity')" v-if="item.editId === editedItem.editId")            
            v-icon(size="18" color="red") mdi-window-close
          v-btn(icon, @click="editItem(item,'Opportunity')" v-if="item.editId != editedItem.editId && isEditable===true")
            v-icon(size="18" ) icon-edit  
          v-btn(icon, @click="save($event,'Opportunity')" v-if="item.editId === editedItem.editId")
            v-icon(size="18" color="green") fas fa-check         
          v-btn(icon, @click="onDelete($event,item,'Opportunity')" v-else)
            v-icon(size="18") icon-remove
    section(v-if='tab== "Contact"')
      //- h3 {{$t('CONTACT.TITLE')}}
      v-data-table.dashboard-table(
        item-key="editId",
        :item-class="rowClass"
        v-sortable-data-table,
        @sorted="saveOrder($event, 'Contact')",
        :headers="dataFieldsHeader",
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}",
        :items="ContactSortDefinition",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        :search="searchMangeUser",
        height="600",
        fixed-header,
        hide-default-footer="hide-default-footer",
        dense
      )
        template( v-slot:item.label="{ item }")
            v-text-field.v-text-field__slot(id="CFtext" v-model="item.label" :hide-details="true" dense single-line :autofocus="true" v-if="item.editId === editedItem.editId")
            .ellipsis(v-else :title='item.label')
                span {{item.label}}
        template(v-slot:item.type="{ item }") 
            .ellipsis(v-for="i in fieldTypes" v-if="item.type == i.value")         
                span {{i.text}}
        template( v-slot:item.mandatory="{ item }")
          v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.mandatory" hide-details="auto" v-if="item.editId === editedItem.editId && item.type != 'bol'") 
          v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.mandatory" hide-details="auto" v-else readonly)
          span(v-else) {{item.mandatory}}        
        template(v-slot:item.active="{ item }")
          v-btn(icon, @click="editOpt(item)" v-if="item.editId === editedItem.editId && (item.type ==='opt'  || item.type ==='mlt' || item.type ==='tst')")
            v-icon(size="18" color="blue") fa-solid fa-bars
          v-btn(icon, @click="close('Contact')" v-if="item.editId === editedItem.editId")            
            v-icon(size="18" color="red") mdi-window-close
          v-btn(icon, @click="editItem(item,'Contact')" v-if="item.editId != editedItem.editId && isEditable===true")
            v-icon(size="18" ) icon-edit  
          v-btn(icon, @click="save($event,'Contact')" v-if="item.editId === editedItem.editId")
            v-icon(size="18" color="green") fas fa-check         
          v-btn(icon, @click="onDelete($event,item,'Contact')" v-else)
            v-icon(size="18") icon-remove
    section(v-if='tab == "Organization"')
      //- h3 {{$t('COMPANY.TITLE')}}
      v-data-table.dashboard-table(
        item-key="editId",
        :item-class="rowClass"
        v-sortable-data-table,
        @sorted="saveOrder($event, 'Organization')",
        :headers="dataFieldsHeader",
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}",
        :items="OrgSortDefinition",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        :search="searchMangeUser",
        height="600",
        fixed-header,
        hide-default-footer="hide-default-footer",
        dense
      )
        template( v-slot:item.label="{ item }")
            v-text-field.v-text-field__slot(id="CFtext" v-model="item.label" :hide-details="true" dense single-line :autofocus="true" v-if="item.editId === editedItem.editId")
            .ellipsis(v-else)
                span(:title='item.label') {{item.label}}
        template(v-slot:item.type="{ item }")          
            .ellipsis(v-for="i in fieldTypes" v-if="item.type == i.value")
                span {{i.text}}
        template( v-slot:item.mandatory="{ item }")
          v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.mandatory" hide-details="auto" v-if="item.editId === editedItem.editId && item.type != 'bol'") 
          v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.mandatory" hide-details="auto" v-else readonly)
          span(v-else) {{item.mandatory}}
        
        template(v-slot:item.active="{ item }")
          v-btn(icon, @click="editOpt(item)" v-if="item.editId === editedItem.editId && (item.type ==='opt' || item.type ==='mlt' || item.type ==='tst')")
            v-icon(size="18" color="blue") fa-solid fa-bars
          v-btn(icon, @click="close('Organization')" v-if="item.editId === editedItem.editId")            
            v-icon(size="18" color="red") mdi-window-close
          v-btn(icon, @click="editItem(item,'Organization')" v-if="item.editId != editedItem.editId && isEditable===true")
            v-icon(size="18" ) icon-edit  
          v-btn(icon, @click="save($event,'Organization')" v-if="item.editId === editedItem.editId")
            v-icon(size="18" color="green") fas fa-check         
          v-btn(icon, @click="onDelete($event,item,'Organization')" v-else)
            v-icon(size="18") icon-remove

    section(v-if='tab == "Case"')      
      //-h3 {{$t('CASE.TITLE')}} 
      v-data-table.dashboard-table(
        item-key="editId",
        :item-class="rowClass"
        v-sortable-data-table,
        @sorted="saveOrder($event, 'Case')",
        :headers="dataFieldsHeader",
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}",
        :items="CaseSortDefinition",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        :search="searchMangeUser",
        height="600",
        fixed-header,
        hide-default-footer="hide-default-footer",
        dense
      )
        template( v-slot:item.label="{ item }")
            v-text-field.v-text-field__slot(id="CFtext" v-model="item.label" :hide-details="true" dense single-line :autofocus="true" v-if="item.editId === editedItem.editId")
            .ellipsis(v-else)
                span(:title='item.label') {{item.label}}
        template(v-slot:item.type="{ item }")          
            .ellipsis(v-for="i in fieldTypes" v-if="item.type == i.value")
                span {{i.text}}
        template( v-slot:item.mandatory="{ item }")
          v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.mandatory" hide-details="auto" v-if="item.editId === editedItem.editId && item.type != 'bol'") 
          v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.mandatory" hide-details="auto" v-else readonly)
          span(v-else) {{item.mandatory}}
        
        template(v-slot:item.active="{ item }")
          v-btn(icon, @click="editOpt(item)" v-if="item.editId === editedItem.editId && (item.type ==='opt' || item.type ==='mlt' || item.type ==='tst')")
            v-icon(size="18" color="blue") fa-solid fa-bars
          v-btn(icon, @click="close('Case')" v-if="item.editId === editedItem.editId")            
            v-icon(size="18" color="red") mdi-window-close
          v-btn(icon, @click="editItem(item,'Case')" v-if="item.editId != editedItem.editId && isEditable===true")
            v-icon(size="18" ) icon-edit  
          v-btn(icon, @click="save($event,'Case')" v-if="item.editId === editedItem.editId")
            v-icon(size="18" color="green") fas fa-check         
          v-btn(icon, @click="onDelete($event,item,'Case')" v-else)
            v-icon(size="18") icon-remove

    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(
      v-model="editOptDialog",
      max-width="400",
      content-class="setting-dialog",
      v-if="editOptDialog === true"
    )
      v-card
        v-card-title.d-flex.justify-center.mb-4 {{$t('CUSTOMFIELDS.EDIT_OPTION')}}
        v-card-text
          v-form(v-if='optType==="opt" || optType==="mlt"' v-model="editOptItems.valid", ref="form" @submit.prevent)
            div
              .text-h6.mb-4 
              v-row(no-gutters)
                v-col.d-flex.align-center(cols="12")
                  h3.my-0 {{$t('CUSTOMFIELDS.OPTION')}}
              v-text-field#options.v-text-field__slot(
                type="text",
                :label=`$t('CUSTOMFIELDS.ERROR_6')`,
                v-model="tempOptions",
                @keyup.enter="addOptions"
              )
              //- draggable(:object='editOptItems.option_set' group="opportunity_set" style='height:98%' @end="dragend($event, editOptItems.option_set)") 
              //- draggable(:value='optList' group="opportunity_set" style='height:98%' @input="dragend($event)")         
              v-card( max-height="330" class='scroll' outlined elevation='0')
                v-card-text(height='100' max-height='260' class='custom-filter-style pa-1 ')
                  v-list(max-height='260' class='pa-0')
                    v-text-field.no-border.v-text-field__slot(
                      type="text",
                      :rules="formRule.fieldRules"
                      prepend-inner-icon="mdi-format-list-bulleted"
                      v-model="editOptItems.option_set[index]",
                      v-for="(item,index) in editOptItems.option_set", :key="index" :append-outer-icon="'icon-cancel'" @click:append-outer="deleteOtptions(index,'old')"
                    )
          v-form(v-else v-model="editTstItems.valid", ref="form" @submit.prevent)
                v-select.no-border.v-text-field__slot(
                  v-model="tree_select_id",
                  :items="treeSelectList",
                  item-text="name",
                  item-value="id",
                  :label=`$t('CUSTOMFIELDS.TREE_OPTION')`,
                  prepend-icon='mdi-equal-box' 
                  :rules="formRule.fieldRules"
                )
          v-card-actions.mt-8.px-0.py-0
            v-row(no-gutters)
              v-col.pr-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onCancelEditOpt",
                  width="100%",
                  height="40",
                  color="cancel",
                  depressed,
                  dark
                ) {{$t('GENERAL.CANCEL')}}
              v-col.pl-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onConfirmEditOpt",
                  width="100%",
                  height="40",
                  color="success",
                  depressed,
                ) {{$t('GENERAL.CONFIRM')}}
      
    v-dialog(
      v-model="customFieldAddDialog",
      max-width="500",
      content-class="setting-dialog",
      v-if="customFieldAddDialog === true"
    )
      v-card
        v-card-title.d-flex.justify-center {{$t('CUSTOMFIELDS.ADD_CF')}}
        v-card-text
          v-form(v-model="addCustomField.valid", ref="form")
            v-select.no-border.v-text-field__slot(
              v-model="addCustomField.page",
              :items="pageLists",
              item-value='key',
              item-text='name',
              prepend-icon='mdi-book-open-page-variant',
              :label=`$t('CUSTOMFIELDS.P_SELECT_PAGE')`,
              :rules="formRule.fieldRules"
            )
            v-select.no-border.v-text-field__slot(
              v-model="addCustomField.dealstage",
              :items="stageDealsList",
              item-text="stage_type_name",
              item-value="id",
              :label=`$t('CUSTOMFIELDS.SELECT_O_PROCESS')`,
              prepend-icon='mdi-equal-box' 
              :rules="formRule.fieldRules"
              v-if='addCustomField.page == "Opportunity"'
            )
            v-select.no-border.v-text-field__slot(
              v-model="addCustomField.casestage",
              :items="stageCasesList",
              item-text="stage_type_name",
              item-value="id",
              :label=`$t('CUSTOMFIELDS.SELECT_C_PROCESS')`,
              prepend-icon='mdi-equal-box' 
              :rules="formRule.fieldRules"
              v-if='addCustomField.page == "Case"'
            )
            v-text-field.no-border.v-text-field__slot(
              v-model="addCustomField.fieldName",
              type="text",
              :label=`$t('CUSTOMFIELDS.NAME')`,
              prepend-icon="mdi-mail",
              :rules="formRule.nameRules"
            )
            v-select.no-border.v-text-field__slot(
              v-model="addCustomField.type",
              :items="fieldTypes",
              text="text",
              value="value",
              :label=`$t('CUSTOMFIELDS.TYPE')`,
              prepend-icon='mdi-equal-box' 
              :rules="formRule.fieldRules"
            )
            v-checkbox.no-border.v-input__slot_checkbox(
              v-model="addCustomField.mandatory",
              :items="pageLists",               
              :label=`$t('CUSTOMFIELDS.MANDATORY')`,
              v-if="addCustomField.type != 'bol'"
            )
            div(v-if="addCustomField.type === 'opt' || addCustomField.type === 'mlt'")
              .text-h6.mb-4 
              v-row(no-gutters)
                v-col.d-flex.align-center(cols="12")
                  h3.my-0 {{$t('CUSTOMFIELDS.OPTION')}}

              v-text-field#options.v-text-field__slot(
                type="text",
                :label=`$t('CUSTOMFIELDS.ERROR_6')`,
                v-model="tempOptions",
                @keyup.enter="addOptions"
              )
              v-text-field.no-border.v-text-field__slot(
                type="text",
                prepend-inner-icon="mdi-format-list-bulleted"
                v-model="newOption[index]",
                :rules="formRule.fieldRules"
                v-for="(item,index) in newOption", :key="index" :append-outer-icon="'icon-cancel'" @click:append-outer="deleteOtptions(index,'new')"
              )

            div(v-if="addCustomField.type === 'tst'")
              .text-h6.mb-4 {{$t('CUSTOMFIELDS.OPTION')}}
              v-select.no-border.v-text-field__slot(
                v-if='!no_tree_option',
                v-model="tree_select_id",
                :items="treeSelectList",
                item-text="name",
                item-value="id",
                :label=`$t('CUSTOMFIELDS.TREE_OPTION')`,
                prepend-icon='mdi-equal-box' 
                :rules="formRule.fieldRules"
            )
              span(v-else) {{$t('TREESETTING.NO_DATA')}}

            div(v-if="addCustomField.type === 'Radio'")
              .text-h6.mb-4 {{$t('CUSTOMFIELDS.OPTION')}}
              v-textarea(solo, :rules="formRule.fieldRules")            
          v-card-actions.mt-8.px-0.py-0
            v-row(no-gutters)
              v-col.pr-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onCancel",
                  width="100%",
                  height="40",
                  color="cancel",
                  depressed,
                  dark
                ) {{$t('GENERAL.CANCEL')}}    
              v-col.pl-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onCustomFieldAdd",
                  width="100%",
                  height="40",
                  color="success",
                  depressed,
                  :disabled='addLoading || (addCustomField.type === "tst" && no_tree_option)'
                ) 
                  v-progress-circular(v-if='addLoading' indeterminate size='20')
                  .t-white(v-else) {{$t('GENERAL.ADD')}}
</template>

<script>
import Vue from "vue";
import Sortable from "sortablejs";
import TreeOptionsDataService from '@/services/TreeOptionsDataService';
import CustomFieldDataService from "@/services/CustomFieldDataService";
import StageDataService from '@/services/StageDataService';
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import draggable from 'vuedraggable';
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  components: {
    draggable,
    messageDialog,
    successDialog,
    errorDialog,
  },
  data() {
    return {  
      // tab
      tab: null,
      items:[{key:"Contact",name:i18n.t('CONTACT.TITLE')}, {key:"Organization",name:i18n.t('COMPANY.TITLE')},{key:"Opportunity",name:i18n.t('DEAL.TITLE')},{key:"Case",name:i18n.t('CASE.TITLE')}],
      optList:[],
      newOptList:{},
      
      addLoading:false,
      isLoading:true,
      stageDealsList:[],
      stageCasesList:[],
      deal_stage_type:null,
      case_stage_type:null,  
      isEditable:true,  
      dragging: false,
      messageDialog: false,
      message: '',
      successDialog: false,
      errorDialog: false,
      errorMessage: '',      
      tempOptions: "",
      options: [],
      newOption:{},
      formRule: {
        nameRules: [
                (v) => !!v.trim() || i18n.t('RULE.RULE_R'),
            ],
        fieldRules: [
                (v) => !!v || i18n.t('RULE.RULE_R'),
            ],
      },
      dataFieldsHeader: [
        { text: i18n.t('CUSTOMFIELDS.MANDATORY'), value: "mandatory", align: "center" , width: '10%' ,class: "blue lighten-5", sortable: false,},
        { text: i18n.t('CUSTOMFIELDS.NAME'), value: "label", align: "left" , width: '40%' ,class: "blue lighten-5", sortable: false, },
        { text: i18n.t('CUSTOMFIELDS.TYPE'), value: "type", align: "left", width: '30%' ,class: "blue lighten-5" , sortable: false,},
        { text: i18n.t('CUSTOMFIELDS.ACTIVE'), value: "active", align: "center", width: '20%' ,class: "blue lighten-5", sortable: false},
      ],
      pageLists: [{key:"Contact",name:i18n.t('CONTACT.TITLE')}, {key:"Organization",name:i18n.t('COMPANY.TITLE')},{key:"Opportunity",name:i18n.t('DEAL.TITLE')},{key:"Case",name:i18n.t('CASE.TITLE')}],
      fieldTypes: [
        { text: "Text", value: "txt" },
        { text: "Number", value: "num" },
        { text: "Date", value: "dat" },
        { text: "Date-Time", value: "dtt" },
        { text: "Select", value: "opt" },
        { text: "Multi-select", value: "mlt" },
        { text: "Checkbox", value: "bol" },
        { text: "String", value: "str" },
        { text: "Tree-Select", value: "tst" },
      ],
      addCustomField: {
        valid: true,
        page: "",
        fieldName: "",
        fieldType: "",
        mandatory: false,
        casestage:"",
        dealstage:""
      },
      editOptItems:{
        valid:true,
        option_set:{}
      },
      editTstItems:{
        valid:true,
        tree_select_id:null
      },
      searchMangeUser: "",
      page: 1,
      pageCount: 1,
      itemPerPage: 100,
      customFieldAddDialog: false,
      editOptDialog: false,
      dataFieldsData: [],
      processItem: [],
      secProcessItem: [],
      DealsSortDefinition: [],
      ContactSortDefinition: [],
      OrgSortDefinition: [],
      CaseSortDefinition: [],
      allCaseData:[],
      allDealData:[],
      treeSelectList:[],
      defaultSelect: null,
      saveNewItem: null,       
      editedItem: {      
      label:'',      
      mandatory: false,
      mode:'',
      delClass: '',
      delData: {},
      dleEvent: null,
      emptyData: false,
      processOptData:{},    
      first:{},
      caseId : null,
      addCaseId:null,
      addDealId:null,
      delCase:null,
      delDeal:null,
      dealId: null,
      noCaseStage:false,
      noDealStage:false,
      max_key:null,
      new_key:null,
      optId:null,
      optType:null,
      tree_select_id:null,
      no_tree_option:false,
    },   
    };
  },
  async created() {
    
    await StageDataService.stageList("deal")
          .then(response => {              
              if(response.data.length == 0)
              {
                this.stageDealsList.push({id:null,stage_type_name:i18n.t('CUSTOMFIELDS.NO_OPPTY_STAGE')});
                this.noDealStage = true;
              }else{
                this.stageDealsList = response.data;
                this.stageDealsList.unshift({id:'all',stage_type_name:i18n.t('GENERAL.ALL')});
              }              
          })      
          .finally(() => (this.isLoading = false));    
    await StageDataService.stageList("case")
          .then(response => {                 
              if(response.data.length == 0)
              {
                this.stageCasesList.push({id:null,stage_type_name:i18n.t('CUSTOMFIELDS.NO_CASE_STAGE')});
                this.noCaseStage = true;
              }else{
                this.stageCasesList = response.data; 
                this.stageCasesList.unshift({id:'all',stage_type_name:i18n.t('GENERAL.ALL')});
              }                  
          })   
          .finally(() => (this.isLoading = false)); 
    await TreeOptionsDataService.list()
          .then((response)=>{
              this.treeSelectList = response.data.filter(el=>  Object.keys(el.option_set).length>0 );
          })

    await CustomFieldDataService.getCustomFieldDetail("Setting")
      .then((response) => {
        this.definition = response.data;
        this.processData();
        this.case_stage_type = this.stageCasesList[0].id;
        this.deal_stage_type = this.stageDealsList[0].id;
      })
      .catch((response) => {
        this.showErrorDialog(response);
      });
          
    this.tab = "Contact"
  },
  methods: {   
    checkExist(item){
      let optCheck = null;
      if(this.addCustomField.type =="mlt" || this.addCustomField.type =="opt"){
        optCheck = Object.values(this.newOption).filter(el => el ==item);
      }else{
        optCheck = Object.values(this.editOptItems.option_set).filter(el => el ==item);
      }
      //  optCheck = Object.values(this.editOptItems.option_set).filter(el => el ==item)
      if((this.optType =="mlt" || this.addCustomField.type =="mlt") && item.includes(",")){
        return [false || i18n.t("RULE.RULE_CF_2")];
      }
      if (optCheck.length >1){
        return [false || i18n.t("RULE.RULE_CF_1")];
      }else{
        return [true];
      }
    },
    rowClass() {        
        const rowClass = 'dataTableColor'
        return rowClass;
    },
    editOpt(item)
    {
      if(item.type === "tst"){
        this.optType = item.type;
        this.tree_select_id = item.tree_select_id;
        this.editOptDialog = true;
        this.optId = item.id;
      }else{
        this.optType = item.type;
        this.max_key = item.max_key ? item.max_key : Math.max(...Object.keys(item.option_set));
        this.editOptDialog = true;
        if(Object.values(this.editOptItems.option_set).length === 0) ;
        {  
        this.editOptItems.option_set = item.option_set;
        }
        this.orginalOpt=JSON.parse(JSON.stringify(this.editOptItems.option_set));
        this.optList = [];
        for(var i in this.editOptItems.option_set)
        {
          this.optList[i] = this.editOptItems.option_set[i];
        }
        this.optId = item.id;
      }
    },
    onCancelEditOpt(){
      for(var i in this.orginalOpt){
        const itemIndex = i.toString();
        Vue.set(this.editOptItems.option_set,itemIndex,this.orginalOpt[itemIndex]);
      }            
      this.editOptDialog = false;
      this.new_key = null;
    },
    onConfirmEditOpt()
    {
        if(this.optType === "tst"){
          this.editTstItems.tree_select_id = this.tree_select_id;
        }else{
          for(let i in this.editOptItems.option_set){
          if(this.checkExist(this.editOptItems.option_set[i])[0] != true){
            this.showErrorDialog(this.checkExist(this.editOptItems.option_set[i])[0]);
            return;
          }
          }
          if(!this.$refs.form.validate())
          {
            return;
          }
          if(Object.entries(this.editOptItems.option_set).length < 2)
          {
            this.showErrorDialog(i18n.t('CUSTOMFIELDS.ERROR'));
            return
          }
        }
        
        this.editOptDialog = false;
    },
    showMessageDialog(message) {
        this.messageDialog = true;
        this.message = message;
    },
    onEmitMessageDialog(val) {
      this.messageDialog = false;
      if(val){
      if(this.mode == 'delItem')
      {
        switch(this.delClass)
      {
        case 'Opportunity':
          this.editedIndex = this.DealsSortDefinition.indexOf(this.delData);
           if(this.DealsSortDefinition.length == 1)
          {
             this.emptyData = true;
             this.delDeal = this.definition.filter(el=>el.class_name == "Opportunity")[0].definition;
              for(let i in this.delDeal)
              {
                if(this.delDeal[i].stage_type_id == this.deal_stage_type)
                {
                  this.delDeal = this.delDeal.filter(el => el!=this.delDeal[i]);
                  break;
                }
              } 
             this.DealsSortDefinition = [];
          } 
          else
          {            
            this.DealsSortDefinition.splice(this.editedIndex, 1);
          }              
           break;
        case 'Contact':
          this.editedIndex = this.ContactSortDefinition.indexOf(this.delData); 
          if(this.ContactSortDefinition.length == 1)
          {             
             this.emptyData = true;
             this.ContactSortDefinition = [];
          } 
          else
          {            
            this.ContactSortDefinition.splice(this.editedIndex, 1);
          }                      
           break;
        case 'Organization':
          this.editedIndex = this.OrgSortDefinition.indexOf(this.delData);
           if(this.OrgSortDefinition.length == 1)
          {             
             this.emptyData = true;
             this.OrgSortDefinition = [];
          } 
          else
          {
            this.OrgSortDefinition.splice(this.editedIndex, 1);
          }
           break;
        case 'Case':
          this.editedIndex = this.CaseSortDefinition.indexOf(this.delData);
           if(this.CaseSortDefinition.length == 1)
          {             
             this.emptyData = true;
             this.delCase = this.definition.filter(el=>el.class_name == "Case")[0].definition;
              for(let i in this.delCase)
              {
                if(this.delCase[i].stage_type_id == this.case_stage_type)
                {                  
                  this.delCase = this.delCase.filter(el => el!=this.delCase[i]);
                  break;
                }
              } 
             this.CaseSortDefinition = [];
             
          } 
          else
          {
            this.CaseSortDefinition.splice(this.editedIndex, 1);
          }
           break;
        default:
          break;
      }
      this.save(this.dleEvent,this.delClass);
      this.delClass = '';
      this.delData = {};
      this.dleEvent = null;
      this.showSuccessDialog();
      this.emptyData = false;
      this.delCase = null;
      this.delDeal = null;
      }
      }
    },
    showSuccessDialog() {
        this.successDialog = true;
    },
    onEmitSuccessDialog() {
        this.successDialog = false;
    },
    showErrorDialog(message) {  
        this.errorDialog = true;
        this.errorMessage = message;
    },
    onEmitErrorDialog() {
      if(this.customFieldAddDialog && (this.noDealStage | this.noCaseStage))
      {
        this.addCustomField.page = "Contact";
      }
        this.errorDialog = false;
        this.addLoading = false;
    },
    save(event,className) {
      if (this.editedIndex > -1) {
        this.saveOrder(event,className);
      }      
      this.close();
    },
    close (className) {
      setTimeout(() => {
        switch(className)
      {
        case 'Opportunity':
          this.DealsSortDefinition = this.defaultItem;
           break;
        case 'Contact':          
          this.ContactSortDefinition = this.defaultItem;
           break;
        case 'Organization': 
         this.OrgSortDefinition = this.defaultItem;
           break;
        case 'Case': 
         this.CaseSortDefinition = this.defaultItem;
           break;
        default:
          break;
      }  
        this.isEditable = true;
        this.editedItem = {};
        this.editedIndex = -1;
        this.editOptItems.option_set = {};
      }, 300)
    },
    editItem (item,className) {
      
      switch(className)
      {
        case 'Opportunity':
          this.editedIndex = this.DealsSortDefinition.indexOf(item);
          this.defaultItem = JSON.parse(JSON.stringify(this.DealsSortDefinition));
           break;
        case 'Contact':
          this.editedIndex = this.ContactSortDefinition.indexOf(item);
          this.defaultItem = JSON.parse(JSON.stringify(this.ContactSortDefinition));
           break;
        case 'Organization':
          this.editedIndex = this.OrgSortDefinition.indexOf(item);
          this.defaultItem = JSON.parse(JSON.stringify(this.OrgSortDefinition));
           break;
        case 'Case':
          this.editedIndex = this.CaseSortDefinition.indexOf(item);
          this.defaultItem = JSON.parse(JSON.stringify(this.CaseSortDefinition));
           break;
        default:
          break;
      } 
      this.isEditable = false;
      this.editedItem = item;
    },
    processData() {
      this.allDealData = [];
      this.allCaseData = [];
      for (let i in this.definition) {
        this.definitionType = this.definition[i].class_name; 
        if(this.definitionType == "Case" | this.definitionType == "Opportunity")
        {
          this.first =  this.definition.filter(
          (d) => d.class_name == this.definition[i].class_name
          )[0].definition;
          for(let i in this.first)
          {
            this.processItem = this.first[i].data;
            if(this.definitionType == "Case")
            {
              this.caseId = this.first[i].stage_type_id;
              }
            else{
            this.dealId = this.first[i].stage_type_id;
            }

            for (let i in this.processItem) {
              let sequenceNumber =
                this.processItem[i]["sequence_number"].toString();
              Vue.set(this.secProcessItem, sequenceNumber, this.processItem[i]);
              Vue.set(this.secProcessItem[sequenceNumber], "type", i.slice(0, 3));
              Vue.set(this.secProcessItem[sequenceNumber], "id", i);
              Vue.set(this.secProcessItem[sequenceNumber], "editId", this.definitionType+"-"+i);                      
            }
            if(this.definitionType == "Case")
            {
              let num =  "id"+this.caseId.toString();
              Vue.set(this.allCaseData, num, this.secProcessItem.filter((el) => el)); 
            }
            else{
              let num =  "id"+this.dealId.toString();
              Vue.set(this.allDealData, num, this.secProcessItem.filter((el) => el)); 
            }
            // this.CaseSortDefinition.num = this.secProcessItem.filter((el) => el); 
            // this.CaseSortDefinition= this.CaseSortDefinition.filter((el) => el); 
            this.secProcessItem = [];
          }          
        }else     
        {
          this.processItem = this.definition.filter(
          (d) => d.class_name == this.definition[i].class_name
          )[0].definition; 

          for (let i in this.processItem) {
          let sequenceNumber =
            this.processItem[i]["sequence_number"].toString();
          Vue.set(this.secProcessItem, sequenceNumber, this.processItem[i]);
          Vue.set(this.secProcessItem[sequenceNumber], "type", i.slice(0, 3));
          Vue.set(this.secProcessItem[sequenceNumber], "id", i);
          Vue.set(this.secProcessItem[sequenceNumber], "editId", this.definitionType+"-"+i);
        }
        switch (this.definitionType) {            
            case "Contact":
              this.ContactSortDefinition = this.secProcessItem.filter((el) => el);
              break;
            case "Organization":
              this.OrgSortDefinition = this.secProcessItem.filter((el) => el);
              break;            
          }         
        this.secProcessItem = [];
        }        
      }      
    },
    addOptions(e) {
      if (e.key === "Enter" && this.tempOptions && !this.editOptDialog) {
        const optCheck = Object.values(this.newOption).includes(this.tempOptions) 
        if(this.addCustomField.type =="mlt" && this.tempOptions.includes(",")){
          return this.tempOptions ="";
        }
        if (!optCheck) {
          this.newOption[Object.entries(this.newOption).length] = this.tempOptions;
        }
        this.tempOptions = "";
      }
      else if(e.key === "Enter" && this.tempOptions && this.editOptDialog){
        const optCheck = Object.values(this.editOptItems.option_set).includes(this.tempOptions);
        if (!optCheck) {
          if(this.optType =="mlt" && this.tempOptions.includes(",")){
            return this.tempOptions ="";
          }
          // this.editOptItems.option_set[Object.entries(this.editOptItems.option_set).length] = this.tempOptions;
          // this.optList[Object.entries(this.editOptItems.option_set).length-1] = this.tempOptions;
          if(this.new_key==null){
            this.editOptItems.option_set[this.max_key+1] = this.tempOptions;
            this.optList[this.max_key+1] = this.tempOptions;
            this.new_key = this.max_key+1;
          }else{
            this.editOptItems.option_set[this.new_key+1] = this.tempOptions;
            this.optList[this.new_key+1] = this.tempOptions;
            this.new_key = this.new_key+1;
          }
        }
        this.tempOptions = "";
      }else{        
        this.showErrorDialog(i18n.t('CUSTOMFIELDS.ERROR_2'));
      }
    },
    deleteOtptions(index,mode) {
      // this.options = this.options.filter(function (item) {
      //   return project !== item;
      // });
      switch(mode)
      {
        case "old":
             this.processOptData = this.editOptItems.option_set;
          break;
        case "new":
             this.processOptData = this.newOption;
          break;
        default:
          break;
      }
      const lastIndex = Object.entries(this.processOptData).length;
      if(mode == "new"){
          for(let i in this.processOptData){
            if(i>index){
              this.processOptData[index++] = this.processOptData[i++];
            }
        }
        delete this.processOptData[lastIndex-1];
      }else{
        for(let i in this.processOptData){
            if(i>index &&index > this.max_key){
              this.processOptData[index++] = this.processOptData[i++];
            }
        }
        delete this.processOptData[index];
        if(this.max_key <= Math.max(...Object.keys(this.processOptData))){
          this.new_key = Math.max(...Object.keys(this.processOptData));
        }
      }
      this.tempOptions = "0";
      this.tempOptions = "";
      delete this.optList[index];
      this.optList = this.optList.filter((el)=>el);
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        return true;
      } else {
        return false;
      }
    },
    onDelete(event,item,className) {
      this.mode = "delItem";
      this.delData = item;
      this.delClass = className;
      this.dleEvent = event;
      this.showMessageDialog(i18n.t('CUSTOMFIELDS.MESSAGE'));
    },
    showAddCustomField() {
      if(this.treeSelectList.length>0){
        this.tree_select_id = this.treeSelectList[0].id;
        this.no_tree_option = false;
      }else{
        this.no_tree_option = true;
      }
      this.newOption = {};
      this.customFieldAddDialog = true;
      if(this.noCaseStage && this.tab == "Case")
      {
        this.addCustomField.page = "Contact";
      }else if(this.noDealStage && this.tab == "Opportunity")
      {
        this.addCustomField.page = "Contact";
      }else
      {
        this.addCustomField.page = this.tab;
        this.addCustomField.dealstage = this.deal_stage_type;
        this.addCustomField.casestage = this.case_stage_type;
      }
      this.cancelData = JSON.parse(JSON.stringify(this.addCustomField));
    },
    paddingLeft(str, lenght) {
      if (str.length >= lenght) return str;
      else return this.paddingLeft("0" + str, lenght);
    },
    onCancel() {
      this.customFieldAddDialog = false;
      this.addCustomField = this.cancelData;
      this.tempOptions = '';
    },    
    async onCustomFieldAdd() {
      if(this.addCustomField.type=="opt" || this.addCustomField.type=="mlt"){
        for(let i in this.newOption){
          if(this.checkExist(this.newOption[i])[0] != true){
            this.showErrorDialog(this.checkExist(this.newOption[i])[0]);
            return;
          }
        }
      }
      if (this.validateForm()) {
        this.addLoading = true;
        this.changeData = {};
        if (this.addCustomField.page == "Opportunity") {
          this.addCustomField.page = "Opportunity";
        }
        if (this.addCustomField.page == "Organization") {
          this.addCustomField.page = "Organization";
        }
        if(this.addCustomField.page == "Case" | this.addCustomField.page == "Opportunity")
        {
            let caseOrOpptyData = [];
            let caseOrOpptyId = "";
            switch(this.addCustomField.page)
            {
                case "Case":                    
                    caseOrOpptyData = this.definition.filter(el=>el.class_name == "Case")[0].definition ?this.definition.filter(el=>el.class_name == "Case")[0].definition:[];
                    caseOrOpptyId = this.addCustomField.casestage;
                break;
                case "Opportunity":
                    caseOrOpptyData = this.definition.filter(el=>el.class_name == "Opportunity")[0].definition ? this.definition.filter(el=>el.class_name == "Opportunity")[0].definition:[];
                    caseOrOpptyId = this.addCustomField.dealstage;
                break;
                default:
                break;
            }
            let newCount = 0;
            if( caseOrOpptyData.length == 0) 
            {              
              caseOrOpptyData = [];
              this.changeData = null;
            }
            else
            {
              for(let i in caseOrOpptyData)
              {
                if(caseOrOpptyData[i].stage_type_id == caseOrOpptyId)
                {
                  if(Object.keys(caseOrOpptyData[i].data).length >0)
                  {
                    this.changeData = caseOrOpptyData[i].data;
                  } 
                  else{
                    this.changeData = null;
                  }               
                }
                else
                {
                  newCount += 1;
                }
                if(newCount == caseOrOpptyData.length)
                  {
                    this.changeData = null;
                  }
              } 
            }
        if(this.changeData == null)
        {         
          this.changeData={};
          let new_data_name = '';
          if(caseOrOpptyId == 'all'){
            new_data_name = this.addCustomField.type+"_all_001";
          }else{
            new_data_name = this.addCustomField.type+"001";
          }
          this.changeData[new_data_name]=
              {
              label: this.addCustomField.fieldName,
              mandatory: this.addCustomField.mandatory,
              sequence_number: 1,
              };
          if (this.addCustomField.type == "opt" || this.addCustomField.type == "mlt") 
            {     
              if(Object.entries(this.newOption).length < 2)
            {
              this.showErrorDialog(i18n.t('CUSTOMFIELDS.ERROR_3'));
              return;
            }
            this.changeData[new_data_name]["option_set"] = this.newOption;
            this.changeData[new_data_name]["max_key"] = Math.max(...Object.keys(this.newOption));
            }
          if(this.addCustomField.type == "tst"){
            this.changeData[new_data_name]["tree_select_id"] = this.tree_select_id;
          }
          if(newCount == caseOrOpptyData.length | newCount == 0)
          {
            if(newCount>0){
              for(let i in caseOrOpptyData)
            {
              if(caseOrOpptyData[i].stage_type_id != caseOrOpptyId)
              {                
                for(let item in caseOrOpptyData[i].data)
                {                  
                  delete caseOrOpptyData[i].data[item].editId;
                  delete caseOrOpptyData[i].data[item].type;
                  delete caseOrOpptyData[i].data[item].id; 
                }     
              }
            } 
            }
            caseOrOpptyData.push({"stage_type_id":caseOrOpptyId,"data":this.changeData});
            this.addCaseId = JSON.parse(JSON.stringify(caseOrOpptyId)) ;
          }else
          {
            for(let i in caseOrOpptyData)
            {
              if(caseOrOpptyData[i].stage_type_id == caseOrOpptyId)
              {                
                caseOrOpptyData[i].data = this.changeData;
                this.addCaseId = JSON.parse(JSON.stringify(caseOrOpptyId));
              }else{
                for(let item in caseOrOpptyData[i].data)
                {                  
                  delete caseOrOpptyData[i].data[item].editId;
                  delete caseOrOpptyData[i].data[item].type;
                  delete caseOrOpptyData[i].data[item].id; 
                }
              }
            } 
          }      
          let changeData = {
              id: this.definition.filter(el=>el.class_name == this.addCustomField.page)[0].id,
              class_name: this.addCustomField.page,
              definition: caseOrOpptyData,
            };
          await CustomFieldDataService.updateSettingCustomField(changeData).then((response) => {
          for (let i in this.definition){
              if(this.definition[i].class_name == this.addCustomField.page)
              {
                this.definition[i] = response.data;
              }
              }
          });
          this.setNewItemPage();
          return;
        }
        else{
        this.dataBeforeSent = JSON.parse(
          JSON.stringify(this.changeData)
        );
        for (let item in this.dataBeforeSent) {
          delete this.dataBeforeSent[item].editId;
          delete this.dataBeforeSent[item].type;
          delete this.dataBeforeSent[item].id;
        }
        let newIdList = [];
        for(let i in Object.keys(this.dataBeforeSent))
        {
          newIdList.push(parseInt(Object.keys(this.dataBeforeSent)[i].slice(-3)))
        }
        let newId = Math.max(...newIdList)+1;
        if(caseOrOpptyId == 'all'){
            this.newItemID =
            this.addCustomField.type +'_all_'+
            this.paddingLeft(newId, 3);
          }else{
            this.newItemID =
            this.addCustomField.type +
            this.paddingLeft(newId, 3);
          }
        // this.newItemID =
        //   this.addCustomField.type +
        //   this.paddingLeft(Object.keys(this.dataBeforeSent).length + 1, 3);

        this.dataContent = {
            label: this.addCustomField.fieldName,
            mandatory: this.addCustomField.mandatory,
            sequence_number: Object.keys(this.dataBeforeSent).length + 1,
          };
        if (this.addCustomField.type == "opt" || this.addCustomField.type == "mlt") {  
          if(Object.entries(this.newOption).length < 2)
          {
            this.showErrorDialog(i18n.t('CUSTOMFIELDS.ERROR_3'));
            return;
          }     
          this.dataContent["option_set"] = this.newOption;
          this.dataContent["max_key"] = Math.max(...Object.keys(this.newOption));
        }
        if(this.addCustomField.type == "tst"){
            this.dataContent["tree_select_id"] = this.tree_select_id;
        }
        
        this.dataBeforeSent[this.newItemID] = this.dataContent;
        this.changeData = this.dataBeforeSent;
        }

            for(let i in caseOrOpptyData)
            {
              if(caseOrOpptyData[i].stage_type_id == caseOrOpptyId)
              {                
                caseOrOpptyData[i].data = this.changeData;
                this.addCaseId = JSON.parse(JSON.stringify(caseOrOpptyId));
              }else{
                for(let item in caseOrOpptyData[i].data)
                {
                  delete caseOrOpptyData[i].data[item].editId;
                  delete caseOrOpptyData[i].data[item].type;
                  delete caseOrOpptyData[i].data[item].id; 
                }
              }              
            } 
          let changeData = {
            id: this.definition.filter(el=>el.class_name == this.addCustomField.page)[0].id,
            class_name: this.addCustomField.page,
            definition: caseOrOpptyData,
          };
        await CustomFieldDataService.updateSettingCustomField(changeData).then((response) => {
          for (let i in this.definition){
              if(this.definition[i].class_name == this.addCustomField.page)
              {
                this.definition[i] = response.data;
              }
            }         
        });        
        this.setNewItemPage();
        return;
        }        
// ------------------------------------------------------------------------------------------------------------------  
        else
        {
          this.changeData = this.definition.filter(
          (d) => d.class_name == this.addCustomField.page
        )[0];
        if(this.changeData.definition == null)
        {
          this.changeData.definition={}
          this.changeData.definition[this.addCustomField.type+"001"]=
          {
          label: this.addCustomField.fieldName,
          mandatory: this.addCustomField.mandatory,
          sequence_number: 1,
          };
          if (this.addCustomField.type == "opt" || this.addCustomField.type == "mlt") 
          {     
              if(Object.entries(this.newOption).length < 2)
              {
                this.showErrorDialog(i18n.t('CUSTOMFIELDS.ERROR_3'));
                return;
              }
              this.changeData.definition[this.addCustomField.type+"001"]["option_set"] = this.newOption;
              this.changeData.definition[this.addCustomField.type+"001"]["max_key"] = Math.max(...Object.keys(this.newOption));
          }
          if(this.addCustomField.type == "tst"){
              this.changeData.definition[this.addCustomField.type+"001"]["tree_select_id"] = this.tree_select_id;
          }
        }
        else{
        this.dataBeforeSent = JSON.parse(
          JSON.stringify(this.changeData.definition)
        );
        let newIdList = [];
        for(let i in Object.keys(this.dataBeforeSent))
        {
          newIdList.push(parseInt(Object.keys(this.dataBeforeSent)[i].slice(-3)))
        }
        let newId = Math.max(...newIdList)+1;
        for (let item in this.dataBeforeSent) {
          delete this.dataBeforeSent[this.dataBeforeSent[item].id].editId;
          delete this.dataBeforeSent[this.dataBeforeSent[item].id].type;
          delete this.dataBeforeSent[this.dataBeforeSent[item].id].id;
        }
        this.newItemID =
          this.addCustomField.type +
          this.paddingLeft(newId, 3);

        this.dataContent = {
          label: this.addCustomField.fieldName,
          mandatory: this.addCustomField.mandatory,
          sequence_number: Object.keys(this.dataBeforeSent).length + 1,
        };
        if (this.addCustomField.type == "opt" || this.addCustomField.type == "mlt") {  
          if(Object.entries(this.newOption).length < 2)
          {
            this.showErrorDialog(i18n.t('CUSTOMFIELDS.ERROR_3'));
            return;
          }     
          this.dataContent["option_set"] = this.newOption;
          this.dataContent["max_key"] = Math.max(...Object.keys(this.newOption));
        }
        if(this.addCustomField.type == "tst"){
            this.dataContent["tree_select_id"] = this.tree_select_id;
        }
        
        this.dataBeforeSent[this.newItemID] = this.dataContent;

        this.changeData.definition = this.dataBeforeSent;
        
        // // delete this.saveNewItem[0].type
        }
        CustomFieldDataService.updateSettingCustomField(this.changeData);
        switch (this.addCustomField.page) {
          case "Contact":
            this.ContactSortDefinition = this.changeData;
            break;
          case "Organization":
            this.OrgSortDefinition = this.changeData;
            break;
        }
        }
        this.processData(); 
        if(this.addCustomField.page == "Contact")
            {               
              await(this.tab="Contact");
        }else{     
              await(this.tab="Organization");
        } 
        this.customFieldAddDialog = false;
        this.addCustomField = this.cancelData;
        this.addLoading = false;
        this.tempOptions = '';
      }
    },    
    dragend(event){
      this.optList = event;
      this.newOptList = {};
      for(let i in this.optList)
      {
        const optnumber = i.toString();
        Vue.set(this.newOptList,optnumber,this.optList[i]);
      }
       for(let i in this.newOptList){
        const itemIndex = i.toString();
        Vue.set(this.editOptItems.option_set,itemIndex,this.newOptList[itemIndex]);
      }
    },
    async saveOrder(event, className) {
      this.changeData = {};
      this.changeDataDefinition = {};
      this.changeDataId = this.definition.filter(
        (d) => d.class_name == className
      )[0].id;

      if(this.emptyData == true)
      {
        if(this.delCase){
          if(Object.keys(this.delCase).length > 0)
          {
            for(let i in this.delCase)
              {
                for(let item in this.delCase[i].data)
                {
                  delete this.delCase[i].data[item].editId;
                  delete this.delCase[i].data[item].type;
                  delete this.delCase[i].data[item].id;
                }
              }
          }
          this.changeData = {
          id: this.changeDataId,
          class_name: className,
          definition: this.delCase,
          };
        }else if(this.delDeal){
          if(Object.keys(this.delDeal).length > 0)
          {
            for(let i in this.delDeal)
              {
                for(let item in this.delDeal[i].data)
                {
                  delete this.delDeal[i].data[item].editId;
                  delete this.delDeal[i].data[item].type;
                  delete this.delDeal[i].data[item].id;
                }
              }
          }
          this.changeData = {
          id: this.changeDataId,
          class_name: className,
          definition: this.delDeal,
          };
        }else
        {
          this.changeData = {
            id: this.changeDataId,
            class_name: className,
            definition: null,
          };
        }  
        await CustomFieldDataService.updateSettingCustomField(this.changeData).then((response)=>{
          for(let i in this.definition)
          {
            if(this.definition[i].class_name == response.data.class_name)
            {
              this.definition[i].definition = response.data.definition;
              break;
            }
          }
        });
        await(this.processData());
        return;
      }
      switch (className) {
        case "Opportunity":{
          const movedItemDeal = this.DealsSortDefinition.splice(event.oldIndex,1)[0];
          this.DealsSortDefinition.splice(event.newIndex, 0, movedItemDeal);
          this.saveNewItem = this.DealsSortDefinition;
          //   CustomFieldDataService.updateSettingCustomField()
          break;}
        case "Contact":{
          const movedItemContact = this.ContactSortDefinition.splice(event.oldIndex,1)[0];
          this.ContactSortDefinition.splice(event.newIndex,0,movedItemContact);
          this.saveNewItem = this.ContactSortDefinition;
          break;}
        case "Organization":{
          const movedItemOrg = this.OrgSortDefinition.splice(event.oldIndex,1)[0];
          this.OrgSortDefinition.splice(event.newIndex, 0, movedItemOrg);
          this.saveNewItem = this.OrgSortDefinition;
          break;}
        case "Case":{
          const movedItemOrg = this.CaseSortDefinition.splice(event.oldIndex,1)[0];
          this.CaseSortDefinition.splice(event.newIndex, 0, movedItemOrg);
          this.saveNewItem = this.CaseSortDefinition;
          break;}
        default:
          break;
      }
      //   this.dataBeforeChange =  JSON.parse(JSON.stringify(this.saveNewItem, null, 2))
      for (let item in this.saveNewItem) {
        this.saveNewItem[item].sequence_number = parseInt(item) + 1;
        this.changeDataDefinition[this.saveNewItem[item].id] =
        this.saveNewItem[item];
        if(this.optId){
          if(this.saveNewItem[item].id == this.optId){
            if(this.optType === "tst"){
              this.changeDataDefinition[this.saveNewItem[item].id].tree_select_id = this.tree_select_id;
            }else{
              if(this.new_key){
                this.changeDataDefinition[this.saveNewItem[item].id].max_key = this.new_key;
              }else{
                this.changeDataDefinition[this.saveNewItem[item].id].max_key = this.max_key;
              }
            }
            
          }
        }
      }
      this.dataBeforeChange = JSON.parse(JSON.stringify(this.changeDataDefinition));
      for (let item in this.dataBeforeChange) {
        delete this.dataBeforeChange[this.dataBeforeChange[item].id].type;
        delete this.dataBeforeChange[this.dataBeforeChange[item].id].editId;
        delete this.dataBeforeChange[this.dataBeforeChange[item].id].id;
      }      
      this.changeData = {
          id: this.changeDataId,
          class_name: className,
          definition: this.dataBeforeChange,
        };
      if(className=="Case"| className=="Opportunity")
      {
        let caseOrOpptyData = {};
        let caseOrOpptyId = "";
        switch(className)
            {
                case "Case":
                    caseOrOpptyData = this.definition.filter(el=>el.class_name == "Case")[0].definition;
                    caseOrOpptyId = this.case_stage_type;
                break;
                case "Opportunity":
                    caseOrOpptyData = this.definition.filter(el=>el.class_name == "Opportunity")[0].definition;
                    caseOrOpptyId = this.deal_stage_type;
                break;
                default:
                break;
            }
        for(let i in caseOrOpptyData)
        {
          if(caseOrOpptyData[i].stage_type_id == caseOrOpptyId)
          {
            caseOrOpptyData[i].data = this.dataBeforeChange;
          }
          if(caseOrOpptyData[i].stage_type_id != caseOrOpptyId)
              {                
                for(let item in caseOrOpptyData[i].data)
                {                  
                  delete caseOrOpptyData[i].data[item].editId;
                  delete caseOrOpptyData[i].data[item].type;
                  delete caseOrOpptyData[i].data[item].id; 
                }     
              }
        } 
        this.changeData = {
          id: this.changeDataId,
          class_name: className,
          definition: caseOrOpptyData,
        };
      }
      await CustomFieldDataService.updateSettingCustomField(this.changeData).then((response)=>{
        for(let i in this.definition)
        {
          if(this.definition[i].class_name == response.data.class_name)
          {
            this.definition[i].definition = response.data.definition;
            break;
          }
        }
        });
      await(this.processData());
      this.editOptItems.option_set = {};
      this.optId = null;
      this.max_key = null;
      this.new_key = null;
    },
    async setNewItemPage(){
      if(this.addCustomField.page == "Opportunity")
            {
              this.deal_stage_type = null;
        }else{
              this.case_stage_type = null;
        }
        await(this.processData())
        if(this.addCustomField.page == "Opportunity")
            {               
              await(this.tab="Opportunity");
              await(this.deal_stage_type = this.addCaseId);
        }else{     
              await(this.tab="Case");
              await(this.case_stage_type = this.addCaseId);
        }
        this.customFieldAddDialog = false;
        this.addCustomField = this.cancelData;
        this.addLoading = false;
        this.tempOptions = '';
    },
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
  watch:{
    "case_stage_type":function(){
      if(this.case_stage_type == null){
        this.CaseSortDefinition = [];
      }
      else{
        let num = "id"+this.case_stage_type.toString();
        this.CaseSortDefinition = this.allCaseData[num];
      }      
    },
    "deal_stage_type":function(){
      if(this.deal_stage_type == null){
        this.DealsSortDefinition = [];
      }
      else{
        let num = "id"+this.deal_stage_type.toString();
        this.DealsSortDefinition = this.allDealData[num];
      }      
    },  
    "addCustomField.page":function(){
      if(this.addCustomField.page == "Deal" && this.noDealStage)
      {        
        this.showErrorDialog(i18n.t('CUSTOMFIELDS.ERROR_4'));
      }
      if(this.addCustomField.page == "Case" && this.noCaseStage)
      {        
        this.showErrorDialog(i18n.t('CUSTOMFIELDS.ERROR_5'));
      }         
    },   
    "addCustomField.type":function(){
      if(this.addCustomField.type == 'bol'){
        this.addCustomField.mandatory = false;
      }
    },
  }
});
</script>
<style lang="css">
.nopadding {
  padding-left: 0;
}
.dataTableColor:hover {
  background-color: rgba(180, 240, 255, 0.164) !important;
}
/* .checkBoxItem {
  background-color: firebrick;
  width: none;
  height: 0px;
}
.checkBoxItem.v-input__slot{
  border-radius: 0px;
        padding: 0px 0px;
  align-content: center;
  width: 0px;
  height: 0px;
  margin-left: 100px;
} */
</style>