<template lang="pug">
#CustomOpptyClosedReason
  v-row.setting-title.align-center.py-3.px-4(no-gutters)
    v-col(cols="12", md="9")
      h3.d-flex.align-center.mb-0
        .cube-icon.small.bg-primary.mr-3
          v-icon(size="19", color="white") mdi-view-list-outline
        span {{$t('SETTING.CUSTOMOPPTYCLOSEDREASON')}}
    v-col.text-md-right.w-100(cols="12", md="3")
      v-btn(@click="showAddReason" height="40" color="green" depressed dark)
        v-icon.mr-1(size="14") icon-add
        span {{$t('CUSTOMOPPTYCLOSEDREASON.ADD')}}
  .px-4.py-3 
    section
      v-data-table.dashboard-table(
        :headers="closedReasonHeader",
        :items="closedReasonList",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        multi-sort
        fixed-header=true,
        hide-default-footer="hide-default-footer",
        dense
      )
        template( v-slot:item.reason="{ item, index }")
          v-text-field.v-text-field__slot(v-if='onEdit && index == editIndex' ref="form" v-model="item.reason" :rules="formRule.reasonRules" dense single-line :autofocus="true" maxlength="20")
          .ellipsis(v-else :title='item.reason')
              span {{item.reason}}
        template( v-slot:item.description="{ item, index }")
          v-text-field.v-text-field__slot(v-if='onEdit && index == editIndex' v-model="item.description" :rules="formRule.descriptionRules" dense single-line :autofocus="true" maxlength="30")
          .ellipsis(v-else :title='item.description')
              span {{item.description}}
        template(v-slot:item.selected="{ item, index }")
          v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.selected" hide-details="auto" v-if="onEdit && index == editIndex && index != 0 && index != 1") 
          v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.selected" hide-details="auto" v-else readonly) 
        template(v-slot:item.active="{ item, index }")
          div(style='height: 36px;')
            v-btn(v-if='onEdit && index == editIndex' icon @click="close(index)")
              v-icon(size="18" color="red") mdi-window-close
            v-btn(v-if='!onEdit' icon @click="editItem(item, index)")
              v-icon(size="18" ) icon-edit
            v-btn(v-if='onEdit && index == editIndex' icon @click="save(item)")
              v-icon(size="18" color="green") fas fa-check
      v-pagination.py-1.mb-3(v-model='page' :length='pageCount' v-if='pageCount > 1' circle :total-visible="10") 
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model="addReasonDialog" max-width="500" content-class="setting-dialog" v-if="addReasonDialog")
      v-card
        v-card-title.d-flex.justify-center {{$t('CUSTOMOPPTYCLOSEDREASON.ADDREASON')}}
        v-card-text
          v-form(v-model="addReasonData.valid", ref="form")
            v-text-field.no-border.v-text-field__slot(
              v-model="addReasonData.reason",
              type="text",
              :label=`$t('CUSTOMOPPTYCLOSEDREASON.NAME')`,
              prepend-icon="mdi-mail",
              :rules="formRule.reasonRules"
            )
            v-textarea.textarea-style.no-border.v-text-field__slot(
              v-model="addReasonData.description",
              type="text",
              :label=`$t('CUSTOMOPPTYCLOSEDREASON.DESCRIPTION')`,
              prepend-icon="mdi-equal-box",
              :rules="formRule.descriptionRules"
              no-resize          
            )
          v-card-actions.mt-8.px-0.py-0
            v-row(no-gutters)
              v-col.pr-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onCancel",
                  width="100%",
                  height="40",
                  color="cancel",
                  depressed,
                  dark
                ) {{$t('GENERAL.CANCEL')}}
              v-col.pl-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onAddReason",
                  width="100%",
                  height="40",
                  color="success",
                  depressed,
                  dark
                ) {{$t('GENERAL.ADD')}}
</template>

<script>
import Vue from "vue";
import CustomOpptyClosedReasonDataService from "@/services/CustomOpptyClosedReasonDataService";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  components: {
    messageDialog,
    successDialog,
    errorDialog,
  },
  data() {
    return {
      // Dialog 相關參數
      messageDialog: false,
      message: '',
      successDialog: false,
      errorDialog: false,
      errorMessage: '',

      addReasonDialog: false,
      addReasonData: {
        valid: true,
        reason: "",
        description: "",
        selected: true
      },
      // Table 用 item
      closedReasonHeader: [      
        {
          text: i18n.t('CUSTOMOPPTYCLOSEDREASON.NAME'),
          value: "reason",
          align: "left",
          width: '30%',
          class: "blue lighten-5",
          sortable: false
        },
        {
          text: i18n.t('CUSTOMOPPTYCLOSEDREASON.DESCRIPTION'), 
          value: "description",
          align: "left",
          width: '40%',
          class: "blue lighten-5",
          sortable: false
        },
        {
          text: i18n.t('CUSTOMOPPTYCLOSEDREASON.SELECTED'),
          value: "selected",
          align: "center",
          width: '15%',
          class: "blue lighten-5",
          sortable: false
        },
        {
          text: i18n.t('CUSTOMOPPTYCLOSEDREASON.ACTIVE'),
          value: "active",
          align: "center",
          width: '15%',
          class: "blue lighten-5",
          sortable: false
        },
      ],
      page: 1,
      pageCount: 1,
      itemPerPage: 12,
      //- data
      closedReasonList:[],
      onEdit: false,
      editIndex: -1,
      delIndex: -1,
      delId: null,
      originalItem: {},
      mode: '',
      //- form rule
      formRule: {
        reasonRules: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')
        ],
        descriptionRules: [
          (v) => /^.{0,30}$/.test(v) || i18n.t('RULE.RULE_30')
        ],
      },
    };
  },
  created() {
    CustomOpptyClosedReasonDataService.opptyclosedreasonlist()
    .then((response)=>{
      this.closedReasonList = response.data;
      for(let index in this.closedReasonList){
        if (index == '0'){
          this.closedReasonList[index].reason = (this.closedReasonList[index].reason == 'CLOSEREASONLIST.2') ? i18n.t('CLOSEREASONLIST.2'):this.closedReasonList[index].reason
          this.closedReasonList[index].description = (this.closedReasonList[index].description == 'CLOSEREASONLIST.2') ? i18n.t('CLOSEREASONLIST.2'):this.closedReasonList[index].description
        }else if (index == '1'){
          this.closedReasonList[index].reason = (this.closedReasonList[index].reason == 'CLOSEREASONLIST.3') ? i18n.t('CLOSEREASONLIST.3'):this.closedReasonList[index].reason
          this.closedReasonList[index].description = (this.closedReasonList[index].description == 'CLOSEREASONLIST.3') ? i18n.t('CLOSEREASONLIST.3'):this.closedReasonList[index].description
        }
      }
    });
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        return true;
      } else {
        return false;
      }
    },
    showMessageDialog(message) {
        this.messageDialog = true;
        this.message = message;
    },
    onEmitMessageDialog(val) {
      this.messageDialog = false;
      if (val) {
          console.log("message dialog option", val)
      }
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {  
      this.errorDialog = true;      
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    // 打開編輯
    editItem (item, index) {
      this.originalItem = JSON.parse(JSON.stringify(item));
      this.editIndex = index;
      this.onEdit = true;
    },
    // 儲存編輯
    save(item) {
      if (this.validateForm()) {
        CustomOpptyClosedReasonDataService.editopptyclosedreason(item);
        this.onEdit = false;
      }
    },
    // 關閉編輯
    close(index) {
      this.$set(this.closedReasonList, index, this.originalItem);
      this.onEdit = false;
    },
    // 新增原因
    showAddReason() {
      this.addReasonDialog = true;
      this.addReasonData = {
        valid: true,
        reason: "",
        description: "",
        selected: true
      }
    },
    onAddReason() {
      if (this.validateForm()) {
        CustomOpptyClosedReasonDataService.createopptyclosedreason(this.addReasonData)
        .then(response => {
          this.closedReasonList.push(response.data);
        });
        this.addReasonDialog = false;
      }
    },
    onCancel() {
      this.addReasonDialog = false;
    },
  },
});
</script>
<style lang="css">
.nopadding {
  padding-left: 0;
}
.dataTableColor:hover {
  background-color: rgba(180, 240, 255, 0.164) !important;
}
.v-input--selection-controls__input{
  margin: 0 !important;
}
</style>