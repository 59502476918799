<template lang="pug">
#CustomFields
  v-row.setting-title.align-center.py-3.px-4(no-gutters)
    v-col(cols="12", md="9")
      h3.d-flex.align-center.mb-0
        .cube-icon.small.bg-primary.mr-3
          v-icon(size="19", color="white") icon-folder
        span {{$t('SETTING.CUSTOMERSERVICESETTING')}}
    //- v-col.text-md-right.w-100(cols="12", md="3")
      v-btn(
        @click="showCSAdd",
        height="40",
        color="green",
        depressed,
        dark
      )
        v-icon.mr-1(size="14") icon-add
        span {{$t('CUSTOMERSERVICESETTING.ADD')}}
  .px-4.py-3 
    v-row(no-gutters).mb-2
        v-col(cols="4").d-flex.justify-start.mr-auto
            v-select(v-model='customerService' :items='stageTypeList' item-text="name" item-value="key" return-object height='40' width='200' flat dense solo background-color='#F0F0F0')
        v-col(cols="4").d-flex.justify-end
          v-btn(@click="showCSAdd" height="40" color="green" depressed dark)
            v-icon.mr-1(size="14") icon-add
            span {{$t('CUSTOMERSERVICESETTING.ADD')}}
    section
      //- h3 {{$t('CUSTOMERSERVICESETTING.TYPE')}} 
      v-data-table.dashboard-table(
        item-key="editId",
        :item-class="rowClass"
        v-sortable-data-table,
        @sorted="saveOrder($event, 'TYPE')",
        :headers="typeFieldsHeader",
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}",
        :items="caseTypeList",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        multi-sort
        :search="searchMangeUser",
        fixed-header,
        hide-default-footer="hide-default-footer",
        dense
        v-if='customerService.key == "Type"'
      )
        template( v-slot:item.index="{ item }" )
            span {{ caseTypeList.indexOf(item)+1 }}
        template( v-slot:item.type_name="{ item }" )
            v-text-field.v-text-field__slot(id="CSTypeName" ref="form" v-model="item.type_name" hide-details="true"  dense single-line :autofocus="true" :rules="formRule.fieldRules" v-if="item.editId === editedItem.editId")
            .ellipsis(v-else :title='item.type_name')
                span {{item.type_name}}
        template( v-slot:item.description="{ item }")
            v-text-field.v-text-field__slot(id="CSTypeDescription" v-model="item.description" :hide-details="true" dense single-line :autofocus="true" v-if="item.editId === editedItem.editId")
            .ellipsis(v-else :title='item.description')
                span {{item.description}}
        template(v-slot:item.type="{ item }")         
            .ellipsis(v-for="i in fieldTypes" v-if="item.type == i.value")
                span {{i.text}}        
        template(v-slot:item.active="{ item }")          
          v-btn(icon, @click="close('TYPE')" v-if="item.editId === editedItem.editId")            
            v-icon(size="18" color="red") mdi-window-close
          v-btn(icon, @click="editItem(item,'TYPE')" v-if="item.editId != editedItem.editId && isEditable===true")
            v-icon(size="18" ) icon-edit  
          v-btn(icon, @click="save($event,'TYPE')" v-if="item.editId === editedItem.editId")
            v-icon(size="18" color="green") fas fa-check         
          v-btn(icon, @click="onDelete($event,item,'TYPE')" v-else)
            v-icon(size="18") icon-remove
    section
      //- h3 {{$t('CUSTOMERSERVICESETTING.GROUP')}} 
      v-data-table.dashboard-table(
        item-key="id",
        :item-class="rowClass"
        v-sortable-data-table,
        @sorted="saveOrder($event, 'GROUP')",
        :headers="groupFieldsHeader",
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}",
        :items="caseGroupList",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        multi-sort
        :search="searchMangeUser",
        fixed-header,
        hide-default-footer="hide-default-footer",
        dense
        v-if='customerService.key == "Group"'
      )
        template( v-slot:item.index="{ item }" )
            span {{ caseGroupList.indexOf(item)+1 }}
        template( v-slot:item.group_name="{ item }")
            v-text-field.v-text-field__slot(id="CSGroupName" ref="form" v-model="item.group_name" :hide-details="true" dense single-line :autofocus="true" :rules="formRule.fieldRules" v-if="item.editId === editedItem.editId")
            .ellipsis(v-else :title='item.group_name')
                span {{item.group_name}}
        template( v-slot:item.description="{ item }")
            v-text-field.v-text-field__slot(id="CSGroupDescription" v-model="item.description" :hide-details="true" dense single-line :autofocus="true" v-if="item.editId === editedItem.editId")
            .ellipsis(v-else :title='item.description')
                span {{item.description}}
        template(v-slot:item.type="{ item }") 
            .ellipsis(v-for="i in fieldTypes" v-if="item.type == i.value")         
                span {{i.text}}               
        template(v-slot:item.active="{ item }")         
          v-btn(icon, @click="close('GROUP')" v-if="item.editId === editedItem.editId")            
            v-icon(size="18" color="red") mdi-window-close
          v-btn(icon, @click="editItem(item,'GROUP')" v-if="item.editId != editedItem.editId && isEditable===true")
            v-icon(size="18" ) icon-edit  
          v-btn(icon, @click="save($event,'GROUP')" v-if="item.editId === editedItem.editId")
            v-icon(size="18" color="green") fas fa-check         
          v-btn(icon, @click="onDelete($event,item,'GROUP')" v-else)
            v-icon(size="18") icon-remove    

    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(
      v-model="CSAddDialog",
      max-width="500",
      content-class="setting-dialog",
      v-if="CSAddDialog === true"
    )
      v-card
        v-card-title.d-flex.justify-center {{$t('CUSTOMERSERVICESETTING.ADD_OPTION')}} 
        v-card-text
          v-form(v-model="addCustomField.valid", ref="form")
            v-select.no-border.v-text-field__slot(
              v-model="addCustomField.page",
              :items="stageTypeList",    
              item-text = "name",
              item-value = "key"          
              prepend-icon='mdi-book-open-page-variant'
              :label=`$t('CUSTOMERSERVICESETTING.USEING_TYPE')`,
              :rules="formRule.fieldRules"
            )
            v-text-field.no-border.v-text-field__slot(
              v-model="addCustomField.fieldName",
              type="text",
              :label=`$t('CUSTOMERSERVICESETTING.NAME')`,
              prepend-icon="mdi-mail",
              :rules="formRule.fieldRules"
            )
            v-textarea.textarea-style.no-border.v-text-field__slot(
              v-model="addCustomField.description",
              type="text",
              :label=`$t('CUSTOMERSERVICESETTING.OPTION_DESCRIPTION')`,
              prepend-icon="mdi-equal-box",   
              no-resize           
            )
          v-card-actions.mt-8.px-0.py-0
            v-row(no-gutters)
              v-col.pr-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onCancel",
                  width="100%",
                  height="40",
                  color="cancel",
                  depressed,
                  dark
                ) {{$t('GENERAL.CANCEL')}}
              v-col.pl-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onCSAdd",
                  width="100%",
                  height="40",
                  color="success",
                  depressed,
                  dark
                ) {{$t('GENERAL.ADD')}}
</template>

<script>
import Vue from "vue";
import Sortable from "sortablejs";
import CaseDataService from "@/services/CaseDataService";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import draggable from 'vuedraggable';
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  components: {
    draggable,
    messageDialog,
    successDialog,
    errorDialog,
  },
  data() {
    return {    
      typeFieldsHeader: [
        // { text: "順序", value: "index", align: "left" , width: '10%' ,class: "blue lighten-5" },        
        { text: i18n.t('CUSTOMERSERVICESETTING.NAME'), value: "type_name", align: "left" , width: '30%' ,class: "blue lighten-5", sortable: false, },
        { text: i18n.t('CUSTOMERSERVICESETTING.OPTION_DESCRIPTION'), value: "description", align: "left", width: '50%' ,class: "blue lighten-5", sortable: false, },
        { text: i18n.t('CUSTOMERSERVICESETTING.ACTIVE'), value: "active", align: "center", width: '15%' ,class: "blue lighten-5", sortable: false},
      ],
      groupFieldsHeader: [
        // { text: "順序", value: "index", align: "left" , width: '10%' ,class: "blue lighten-5" },
        { text: i18n.t('CUSTOMERSERVICESETTING.NAME'), value: "group_name", align: "left" , width: '30%' ,class: "blue lighten-5", sortable: false},
        { text: i18n.t('CUSTOMERSERVICESETTING.OPTION_DESCRIPTION'), value: "description", align: "left", width: '50%' ,class: "blue lighten-5", sortable: false},
        { text: i18n.t('CUSTOMERSERVICESETTING.ACTIVE'), value: "active", align: "center", width: '15%' ,class: "blue lighten-5", sortable: false},
      ],
      caseGroupList:[],
      caseTypeList:[],
      caseCategoryList:[],
      isEditable:true, 
      formRule: {
        fieldRules: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
          ],
      },
      editedIndex:null,
      stageTypeList:[
        {key: "Type", name: i18n.t('CUSTOMERSERVICESETTING.TYPE')},
        {key: "Group", name: i18n.t('CUSTOMERSERVICESETTING.GROUP')},
      ],
      customerService: {key: "Type", name: "案件類型"},
      newData:{},
      addCustomField: {
        valid: true,
        page: "",
        fieldName: "",
        description: "",
        sequence_number: null
      },
      editedItem: {},  
      CSAddDialog: false,       
      messageDialog: false,
      message: '',
      successDialog: false,
      errorDialog: false,
      errorMessage: '',      
      searchMangeUser: "",
      page: 1,
      pageCount: 1,
      itemPerPage: 12,
    };
  },
  async created() {
    await CaseDataService.getcasegrouplist().then((response)=>{
      this.caseGroupList = response.data;
      this.processGroup();
    });

    await CaseDataService.getcasetypelist().then((response)=>{
      this.caseTypeList = response.data;
      this.processType();
    });

    await CaseDataService.getcasecategorylist().then((response)=>{
      this.caseCategoryList = response.data;
      this.processCategory();
    });
  },
  methods: {
    rowClass() {        
        const rowClass = 'dataTableColor'
        return rowClass;
    },
    processType() {            
        for (let i in this.caseTypeList) {
          if(!this.caseTypeList[i].editId)          
          {
            Vue.set(this.caseTypeList[i], "editId", "TYPE-"+this.caseTypeList[i].id);
          }
        }
    },
    processGroup() {            
        for (let i in this.caseGroupList) {          
          if(!this.caseGroupList[i].editId){
            Vue.set(this.caseGroupList[i], "editId", "GROUP-"+this.caseGroupList[i].id);
          }
        }
    },
    processCategory() {
        for (let i in this.caseCategoryList) {          
          if(!this.caseCategoryList[i].editId){
            Vue.set(this.caseCategoryList[i], "editId", "CATEGORY-"+this.caseCategoryList[i].id);
          }
        }
    },
    showMessageDialog(message) {
        this.messageDialog = true;
        this.message = message;
    },
    // 執行刪除
    onEmitMessageDialog(val) {
      this.messageDialog = false;
      if(val){
      if(this.mode == 'delItem')
      {
        switch(this.delClass)
      {
        case 'TYPE':
          {
          this.editedIndex =this.caseTypeList.indexOf(this.delData); 
          const delNum =  JSON.parse(JSON.stringify(this.editedIndex))
          CaseDataService.casetypedelete(this.delData.id).then((response)=>{
          if (response.data.error=="This type is in use")
          {
            this.showErrorDialog(i18n.t('CUSTOMERSERVICESETTING.ERROR_2'))
          }
          else{
          if(this.caseTypeList.length == 1)
          {             
             this.emptyData = true
             this.caseTypeList = []
          } 
          else
          {
            const updateList = []
            this.editedIndex =this.caseTypeList.indexOf(this.delData);
            this.caseTypeList.splice(delNum, 1);
            for(let i in this.caseTypeList)
            {     
              if(i!=this.editedIndex)
              {
                const newIndex = this.caseTypeList.indexOf(this.caseTypeList[i])+1
                const updateData = this.caseTypeList[i]
                updateData.sequence_number = newIndex
                updateList.push(updateData)
              }                        
            }            
            CaseDataService.casetypesequence_number({"updateList":updateList}).then((response)=>{
              this.caseTypeList = response.data.updateList;   
            })
          }
          this.showSuccessDialog()
          }
          })
           break;
          }
        case 'GROUP':
        {
          this.editedIndex = this.caseGroupList.indexOf(this.delData);  
          const delNumG =  JSON.parse(JSON.stringify(this.editedIndex))
          CaseDataService.casegroupcdelete(this.delData.id).then((response)=>{
            if (response.data.error=="This group is in use")
            {
              this.showErrorDialog(i18n.t('CUSTOMERSERVICESETTING.ERROR_1'))
            }
            else{
            if(this.caseGroupList.length == 1)
            {             
              this.emptyData = true
              this.caseGroupList = []
            } 
            else
            {
              const updateList = []
              this.caseGroupList.splice(delNumG, 1);
              for(let i in this.caseGroupList)
              {
                if(i!=this.editedIndex)
                {
                  const newIndex = this.caseGroupList.indexOf(this.caseGroupList[i])+1
                  const updateData = this.caseGroupList[i]
                  updateData.sequence_number = newIndex
                  updateList.push(updateData)
                }
              }
              CaseDataService.casegroupsequence_number({"updateList":updateList}).then((response)=>{
                this.caseGroupList = response.data.updateList;
              });
            }
            this.showSuccessDialog()
            }
          });
          break;
        }

        case 'CATEGORY':
        {
          this.editedIndex = this.caseCategoryList.indexOf(this.delData);  
          const delNumG =  JSON.parse(JSON.stringify(this.editedIndex))
          CaseDataService.casecategorycdelete(this.delData.id).then(()=>{                    
            if(this.caseCategoryList.length == 1)
            {             
              this.emptyData = true
              this.caseCategoryList = []
            } 
            else
            {            
              const updateList = []
              this.caseCategoryList.splice(delNumG, 1);
              for(let i in this.caseCategoryList)
              {
                if(i!=this.editedIndex)
                {
                  const newIndex = this.caseCategoryList.indexOf(this.caseCategoryList[i])+1
                  const updateData = this.caseCategoryList[i]
                  updateData.sequence_number = newIndex
                  updateList.push(updateData)
                }
              }
              CaseDataService.casecategorysequence_number({"updateList":updateList}).then((response)=>{
                this.caseCategoryList = response.data.updateList;
              });
            }
          });
          break;
        }

        default:
          break;
      }
      this.delClass = ''
      this.delData = {}
      this.dleEvent = null
      this.editedIndex = null
      this.emptyData = false
      }
      }        
    },
    showSuccessDialog() {
        this.successDialog = true;
    },
    onEmitSuccessDialog() {
        this.successDialog = false;
    },
    showErrorDialog(message) {  
        this.errorDialog = true;      
        this.errorMessage = message;
    },
    onEmitErrorDialog() {
        this.errorDialog = false;
    },
    // 儲存編輯
    save(event,className) {
      if (this.editedIndex > -1 && this.validateForm()) {    
        if(className == "TYPE")
        {        
          delete this.editedItem.editId
          CaseDataService.casetypeupdate(this.editedItem).then(()=>{
            // this.caseTypeList[this.editedIndex].type_name = response.data.type_name
            // this.caseTypeList[this.editedIndex].description = response.data.description
            this.processType()             
          });
        }
        if(className == "GROUP")
        {
          delete this.editedItem.editId
          CaseDataService.casegroupupdate(this.editedItem).then(()=>{          
              // this.caseGroupList[this.editedIndex].group_name = response.data.group_name
              // this.caseGroupList[this.editedIndex].description = response.data.description
              this.processGroup()             
            })
        }
        if(className == "CATEGORY")
        {
          delete this.editedItem.editId
          CaseDataService.casecategoryupdate(this.editedItem).then(()=>{
              // this.caseCategoryList[this.editedIndex].category_name = response.data.category_name
              // this.caseCategoryList[this.editedIndex].description = response.data.description
              this.processCategory()             
            })
        }
        this.close()
      }
    },
    // 關閉編輯
    close (className) {     
      switch(className)
      {
        case 'TYPE':
          this.caseTypeList = this.defaultItem
          break;
        case 'GROUP':
          this.caseGroupList = this.defaultItem
          break;
        case 'CATEGORY':
          this.caseCategoryList = this.defaultItem
          break;
        default:
          break;
      }
      this.isEditable = true
      this.editedItem = {}
      this.editedIndex = -1;
    },
    // 打開編輯
    editItem (item,className) {
      switch(className)
      {
        case 'TYPE':
          this.editedIndex = this.caseTypeList.indexOf(item);
          this.defaultItem = JSON.parse(JSON.stringify(this.caseTypeList));
          break;
        case 'GROUP':
          this.editedIndex = this.caseGroupList.indexOf(item);
          this.defaultItem = JSON.parse(JSON.stringify(this.caseGroupList));
          break;
        case 'CATEGORY':
          this.editedIndex = this.caseCategoryList.indexOf(item);
          this.defaultItem = JSON.parse(JSON.stringify(this.caseCategoryList));
          break;
        default:
          break;          
      }
      this.isEditable = false     
      this.editedItem = item     
    },   

    validateForm() {
      if (this.$refs.form.validate()) {
        return true;
      } else {
        return false;
      }
    },
    // 刪除確認
    onDelete(event,item,className) {
      this.mode = "delItem"
      this.delData = item
      this.delClass = className
      this.dleEvent = event
      this.showMessageDialog(i18n.t('CUSTOMERSERVICESETTING.MESSAGE'));
    },
    // 顯示ADD Dialog
    showCSAdd() {
      this.CSAddDialog = true;
      this.cancelData = JSON.parse(JSON.stringify(this.addCustomField));      
    },   
    // 取消編輯
    onCancel() {
      this.CSAddDialog = false;
      this.addCustomField = this.cancelData;      
    },
    // 新增選項
    onCSAdd() {
      if (this.validateForm()) {   
        this.newData = {}            
        if (this.addCustomField.page == "Type") {
          this.newData.type_name = this.addCustomField.fieldName
          this.newData.description = this.addCustomField.description
          this.newData.sequence_number = this.caseTypeList.length+1
          CaseDataService.casetypecreate(this.newData).then((response)=>
          {            
            this.caseTypeList.push(response.data)
            this.processType()
          });
        }
        if (this.addCustomField.page == "Group") {
          this.newData.group_name = this.addCustomField.fieldName
          this.newData.description = this.addCustomField.description
          this.newData.sequence_number = this.caseGroupList.length+1
          CaseDataService.casegroupcreate(this.newData).then((response)=>
          {            
            this.caseGroupList.push(response.data)
            this.processGroup()
          });
        }
        if (this.addCustomField.page == "Category") {
          this.newData.category_name = this.addCustomField.fieldName
          this.newData.description = this.addCustomField.description
          this.newData.sequence_number = this.caseGroupList.length+1
          CaseDataService.casecategorycreate(this.newData).then((response)=>
          {            
            this.caseCategoryList.push(response.data)
            this.processCategory()
          });
        }
        this.CSAddDialog = false;
        this.addCustomField = this.cancelData;
      }
    },
    // 更換順序
    saveOrder(event, className) {    
      if(this.editedItem.id){
        this.save(className)
      }

      if(className == "TYPE")
      {
        const updateList = []
        const movedItem = this.caseTypeList.splice(event.oldIndex,1)[0];
        this.caseTypeList.splice(event.newIndex, 0, movedItem);
        for(let i in this.caseTypeList)
        {     
          const newIndex = this.caseTypeList.indexOf(this.caseTypeList[i])+1
          const updateData = this.caseTypeList[i]
          updateData.sequence_number = newIndex
          updateList.push(updateData)
                    
        }
        CaseDataService.casetypesequence_number({"updateList":updateList}).then((response)=>{
          this.caseTypeList = response.data.updateList;   
        }) 
      }

      if(className == "GROUP")
      {
        const updateList = []
        const movedItem = this.caseGroupList.splice(event.oldIndex,1)[0];
        this.caseGroupList.splice(event.newIndex, 0, movedItem);
        for(let i in this.caseGroupList)
        {     
          const newIndex = this.caseGroupList.indexOf(this.caseGroupList[i])+1
          const updateData = this.caseGroupList[i]
          updateData.sequence_number = newIndex
          updateList.push(updateData)
        }
        CaseDataService.casegroupsequence_number({"updateList":updateList}).then((response)=>{
          this.caseGroupList = response.data.updateList;   
        }) 
      }
      
      if(className == "CATEGORY")
      {
        const updateList = []
        const movedItem = this.caseCategoryList.splice(event.oldIndex,1)[0];
        this.caseCategoryList.splice(event.newIndex, 0, movedItem);
        for(let i in this.caseCategoryList)
        {     
          const newIndex = this.caseCategoryList.indexOf(this.caseCategoryList[i])+1
          const updateData = this.caseCategoryList[i]
          updateData.sequence_number = newIndex
          updateList.push(updateData)
        }
        CaseDataService.casecategorysequence_number({"updateList":updateList}).then((response)=>{
          this.caseCategoryList = response.data.updateList;   
        }) 
      }
    },
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
});
</script>
<style lang="css">
.nopadding {
  padding-left: 0;
}
.dataTableColor:hover {
  background-color: rgba(180, 240, 255, 0.164) !important;
}
</style>